.aside-book {
  margin-top: 50px;
  padding: 40px 30px;
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 20px;
  @include adpt($tab-r) {
    display: none;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;

    &:hover {
      & .aside-book__img {
        transform: scale(1.05);
      }
    }
  }

  &__img-wrap {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    aspect-ratio: 150/200;
    width: 100%;
    max-width: 150px;
    margin-bottom: 15px;
    height: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

    @include adpt($bmob-r) {
      aspect-ratio: 150/200;
    }
    &:hover {
      &::after {
        opacity: 0;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 1;
      transition: opacity 0.3s;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }

  &__name {
    color: $grey;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 6px;

    @include adpt($bmob-r) {
      font-size: 18px;
    }
  }

  &__author {
    color: $grey;

    @include adpt($bmob-r) {
      font-size: 15px;
    }
  }
}

$grey: var(--grey-color);
$white: var(--white-color);
$green: var(--green-color);
$greenLight: var(--green-light-color);
$yellowLight: var(--yellow-light-color);
$yellow: var(--yellow-color);
$blue: var(--blue-color);
$purple: var(--purple-color);
$red: var(--red-color);
$blueLight: var(--blue-light-color);

$mainFont: var(--main-font);

:root {
  --grey-color: #67676a;
  --white-color: #ffffff;
  --green-color: #a1da67;
  --green-light-color: #d0ecb3;
  --yellow-light-color: #fff1cb;
  --yellow-color: #ffd152;
  --blue-color: #8fa9ff;
  --red-color: rgb(219, 81, 46);
  --purple-color: #ff80dd;
  --blue-light-color: #dde5ff;

  --main-font: "Raleway", sans-serif;
}

$big-r: 1380;
$desm-r: 1280;
$tab-r: 1024;
$mtab-r: 980;
$stab-r: 768;
$bmob-r: 576;
$smob-r: 400;

@mixin adpt($width) {
  @media (max-width: #{$width}px) {
    @content;
  }
}

@mixin adpt-mob($width) {
  @media (min-width: #{$width}px) {
    @content;
  }
}

@include adpt($bmob-r) {
}

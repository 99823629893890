.listLessons {
  &__title {
    margin-bottom: 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__list {
    margin-top: 50px;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  &__item-title {
    font-weight: 700;
    font-size: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey;
    margin-bottom: 30px;
  }

  &__list-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @include adpt($stab-r) {
      grid-template-columns: 1fr;
    }
  }

  &__link {
    position: relative;
    display: block;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
    font-size: 18px;
    line-height: 1.3;
    background-color: #ffebfa;

    &:hover {
      font-weight: 700;
      letter-spacing: -0.025em;
    }
  }
  &__info-text {
    margin-right: 20px;
    @include adpt($bmob-r) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  &__info-btn {
    text-wrap: nowrap;
  }
  &__info-block {
    padding: 40px 30px;
    width: 100%;
    border: 1px solid #67676a;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include adpt($bmob-r) {
      flex-direction: column;
      padding: 30px 15px;
      align-items: flex-start;
    }

    &__background {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: #dde5ff;
      z-index: -1;
      box-shadow: 4px 4px 10px 0px #6b80894d;
    }

    &__attention {
      margin-top: 12px;
    }
  }
  &__count {
    font-weight: 700;
    font-size: 25px;

    @include adpt($bmob-r) {
      font-size: 18px;
    }
  }

  &__item-2 {
    &._complete {
      & a {
        padding-left: 29px;
        background-color: #dde5ff;

        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("../../../images/icon/check3.svg");
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.ant {
  &__select {
    max-width: 240px;
    width: 100%;
    outline: none;
    height: 42px;
    font: inherit;

    @include adpt($bmob-r) {
      height: 39px;
    }

    &._school {
      max-width: 400px;
    }

    &_max {
      max-width: 100%;
    }

    &.ant-select-open {
      & .ant-select-arrow {
        transform: rotate(180deg) translateY(50%);
      }
    }

    &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $white;
      border-radius: 20px;
      transition: box-shadow 0.3s;
      padding: 9px 20px 8px;
      padding-right: 20px;
      border: 1px solid $grey;
      font-size: 18px;
      line-height: 1.3;
      color: $grey;
      transition: all 0.3s;

      @include adpt($bmob-r) {
        padding: 10px 20px 7px;
        font-size: 15px;
      }
    }

    &.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
      .ant-select-selector {
      border-color: $grey;
      box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);
    }
  }
}

.ant-select-dropdown {
  z-index: 10002;
  padding: 0;
  font-size: 18px;
  line-height: 1.3;
  color: $grey;
  box-shadow: none;
  overflow: visible;
  background-color: transparent;

  @include adpt($bmob-r) {
    font-size: 15px;
  }
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: $grey;
}

.ant-select-open {
  & .ant-select-selector {
    border-radius: 20px 20px 0 0 !important;
    border-bottom-color: transparent !important;
  }
}

.ant-select-dropdown > div {
  position: relative;
  margin-top: -4px;
  border-radius: 0 0 20px 20px;
  border: 1px solid $grey;
  background-color: $white;
  border-top: none;
  transition: all 0.3s;
  overflow: hidden;
}
.ant-modal {
  &.modal-bg-blue {
    & .ant-modal-content {
      border-radius: 20px;
      padding: 20px 30px;
      padding-top: 40px;
      background-color: #dde5ff;

      @include adpt($bmob-r) {
        padding: 30px 15px;
      }
    }
    & .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include adpt($bmob-r) {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
.ant-select-dropdown-hidden > div {
  border-radius: 20px !important;
}

.ant-select-dropdown .ant-select-item {
  font-size: 18px;
  line-height: 1.3;
  color: $grey;
  padding: 9px 20px 8px;
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 400;
  font-family: $mainFont;

  @include adpt($bmob-r) {
    font-size: 15px;
  }
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.rc-virtual-list-scrollbar-thumb {
  background: $greenLight !important;
}

.rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-vertical {
  width: 4px !important;
}

.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical {
  height: calc(100% - 10px);
}

.ant-picker {
  max-width: 100%;
  width: 100%;
  padding: 8px 20px 9px;
  background-color: var(--white-color);
  border-radius: 20px;
  transition: box-shadow 0.3s;
  border: 1px solid $grey;

  @include adpt($bmob-r) {
    padding: 7px 20px 6px;
  }

  &:hover {
    border-color: $grey;
  }

  &.ant-picker-focused {
    box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);
    border-color: $grey;
  }
}

.ant-picker .ant-picker-input > input {
  font-size: 18px;
  line-height: 1.3;
  color: $grey;
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 400;
  font-family: $mainFont;

  @include adpt($bmob-r) {
    font-size: 15px;
  }

  &::placeholder {
    color: $grey;
    opacity: 0.6;

    @include adpt($bmob-r) {
      font-size: 15px;
    }
  }
}

.anticon.anticon-calendar {
  width: 24px;
  height: 24px;
  background-image: url("../../../images/icon/calendar-days.svg");
  background-repeat: no-repeat;
  background-size: contain;

  & > * {
    display: none;
  }
}

.ant-picker .ant-picker-clear {
  right: 5px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: $grey;
  opacity: 0.6;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: $grey;
  opacity: 1;
}

.ant-select .ant-select-arrow {
  background-image: url("../../../images/icon/chevron- down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
  margin-top: 0;
  transform: translateY(-50%);

  & > * {
    display: none;
  }
}

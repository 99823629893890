.notification {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
  }

  &__block {
    border-radius: 20px;
    padding: 20px 30px;
    background-color: #dde5ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    @include adpt($bmob-r) {
      flex-direction: column;
      padding: 30px 15px;
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    @include adpt($bmob-r) {
      width: 100%;
    }
  }
  &__block2 {
    border-radius: 20px;
    padding: 20px;
    background-color: #d0ecb3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__title {
    margin: 10px 0;
    line-height: 1.2;
    color: $grey;
  }

  &__text {
    text-align: left;
    line-height: 1.2;
    font-size: 15px;
    color: $grey;
  }

  &__icon {
    background-image: url("../../../images/cat/img_cat_sitting.svg");
    height: auto;
    flex: 1;
    margin-right: 30px;
    aspect-ratio: 220/243;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 220px;
    pointer-events: none;
  }
  &__icon2 {
    background-image: url("../../../images/cat/Group.svg");
    background-size: 100%;
    width: 300px;
    height: 195px;
  }
  &__icon3 {
    background-image: url("../../../images/cat/class3z.svg");
    width: 264px;
    height: 186px;
  }

  &__btn {
    color: $grey;
    transition: all 0.3s;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      color: $grey;
    }
  }
  &__btns {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
}

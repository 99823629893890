.cabinet {
  position: relative;
  margin-bottom: 100px;

  @include adpt($tab-r) {
    margin-bottom: 64px;
  }

  &__grid {
    position: relative;
    z-index: 102;
    display: grid;
    grid-template-columns: 310px 1fr;
    grid-gap: 30px;

    @include adpt($desm-r) {
      grid-template-columns: 263px 1fr;
    }
    @include adpt($tab-r) {
      grid-template-columns: 1fr;
      grid-gap: 64px;
      max-width: 852px;
    }
  }

  &__decor {
    position: absolute;
    object-fit: contain;
    z-index: 0;
    pointer-events: none;

    @include adpt($bmob-r) {
      display: none;
    }

    &._1 {
      max-width: 35%;
      height: auto;
      top: -90px;
      right: 0;

      @include adpt($tab-r) {
        transform: translateX(90%);
      }
    }
  }

  &__content {
    min-width: 0;
  }

  &__aside-top {
    padding: 20px;
    border-radius: 20px;
    background-color: $white;
    border: 1px solid $grey;
    margin-bottom: 50px;

    @include adpt($tab-r) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }

    @include adpt($bmob-r) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__aside-top-name {
    margin-bottom: 10px;

    @include adpt($bmob-r) {
      margin-bottom: 5px;
    }
  }

  &__aside-top-btn {
    display: none;

    @include adpt($tab-r) {
      display: block;
      background-color: $greenLight;
      border-color: $greenLight;
      margin-left: 25px;
      flex-shrink: 0;
    }

    @include adpt($bmob-r) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  &__nav {
    background-color: $greenLight;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(#6b8089, 0.3);
    padding: 40px 30px;

    @include adpt($tab-r) {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
    }

    @include adpt($bmob-r) {
      margin-right: 0;
      max-width: 263px;
    }
  }

  &__menu-link {
    border-radius: 20px;
    display: block;
    border: 1px solid $grey;
    display: flex;
    width: 100%;
    padding: 5px 15px;
    align-items: center;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover,
    &._active {
      background-color: $yellow;
      border-color: $yellow;
    }

    &._exit {
      &:hover {
        background-color: rgb(255, 147, 147);
        border-color: rgb(255, 147, 147);
      }
    }
  }

  &__menu-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__menu-img {
    flex-shrink: 0;
    margin-right: 5px;
  }

  &__menu {
    @include adpt($tab-r) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;

      height: 100%;
      background-color: $white;
      z-index: 200;
      padding: 80px 20px 20px;
      overflow-y: auto;
      opacity: 0;
      transform: translateY(10px);
      pointer-events: none;
      transition: all 0.4s ease;

      &._open {
        opacity: 1;
        transform: translateZ(0);
        pointer-events: all;
      }
    }
  }

  &__menu-close-btn {
    display: none;

    @include adpt($tab-r) {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
    }

    &:hover {
      & .cabinet__menu-close-2 {
        fill: $yellow;
      }
    }
  }

  &__menu-close-2 {
    transition: fill 0.3s;
  }
}
